import React from "react";
import "../../assets/css/SutraPages.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Subscribe } from "../Subscribe/Subscribe";

export const OnDemand = () => {
  const hieght = window.screen.width < 800 ? "2000px" : "1850px";

  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="about-me-container">
        <div className="breathwork-overview-title">Breathe on Demand</div>
        <div className="schedule-container">
          <iframe
            title="on demand breathwork Lo"
            id="sutraWidgetIframe"
            src="https://widgets.sutra.fit/iframe/bvq9poiwdutbh2avovtjimz4cnf3/videos"
            width="100%"
            height={hieght}
            frameBorder="0"
            allowfullscreen
          ></iframe>
          <script src="https://widgets.sutra.fit/scripts/embed.js"></script>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};
