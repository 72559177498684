import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./assets/css/App.css";
import { HomePage } from "./components/Homepage/HomePage.jsx";
import { About } from "./components/About/About.jsx";
import { ConnectPage } from "./components/Connect/Connect";
import { Blog } from "./components/Blog/Blog";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { BreathworkOverview } from "./components/BreathworkOverview/BreathworkOverview";
import { Schedule } from "./components/Schedule/Schedule";
import { OnDemand } from "./components/OnDemand/OnDemand";
import { PrivateBreathwork } from "./components/PrivateBreathwork/PrivateBreathwork";
import { FiveDaysOfBreathwork } from "./components/FiveDaysOfBreathwork/FiveDaysOfBreathwork";
export const SidebarContext = React.createContext();

function App() {
  const [menuOpenState, setMenuOpenState] = useState(false);
  return (
    <div className="App">
      <SidebarContext.Provider
        value={{
          isMenuOpen: menuOpenState,
          toggleMenu: () => setMenuOpenState(!menuOpenState),
        }}
      >
        <Sidebar />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/connect">
            <ConnectPage />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/breathwork">
            <BreathworkOverview />
          </Route>
          <Route path="/schedule">
            <Schedule />
          </Route>
          <Route path="/on-demand">
            <OnDemand />
          </Route>
          <Route path="/private-healing">
            <PrivateBreathwork />
          </Route>
          <Route path="/five-days-breathwork">
            <FiveDaysOfBreathwork />
          </Route>
        </Switch>
      </SidebarContext.Provider>
    </div>
  );
}

export default App;
