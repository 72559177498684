import React, { useEffect } from "react";

export const Subscribe = () => {
  useEffect(() => {
    window.fd("form", {
      formId: "5f2edaa32e94b80027c83c55",
      containerEl: "#fd-form-5f2edaa32e94b80027c83c55",
    });
  }, []);

  return (
    <div className="subscribe-container">
      <div
        id="fd-form-5f2edaa32e94b80027c83c55"
        className="subscribe-widget"
      ></div>
    </div>
  );
};
