import { slide as Menu } from "react-burger-menu";
import React, { useContext } from "react";
import { SidebarContext } from "../../App.jsx";
import "../../assets/css/App.css";

export const Sidebar = () => {
  const ctx = useContext(SidebarContext);
  return (
    <Menu className="side-menu" right isOpen={ctx.isMenuOpen}>
      <div className="menu-items-container">
        <h1>FROM LO WITH LOVE</h1>
        <a id="home" className="menu-item" href="/">
          Home
        </a>
        <a id="about" className="menu-item" href="/about">
          About
        </a>
        <a className="menu-item" href="/breathwork">
          Breathwork
        </a>
        <a className="menu-item" href="/schedule">
          Schedule
        </a>
        <a className="menu-item" href="/private-healing">
          Private Sessions
        </a>
        <a id="contact" className="menu-item" href="/connect">
          Contact
        </a>
        <a
          href="/schedule"
          style={{ textDecoration: "none", color: "white", width: "100px" }}
        >
          <div
            className="sign-up-button"
            style={{
              width: "150px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Sign Up Now
          </div>
        </a>
      </div>
    </Menu>
  );
};
