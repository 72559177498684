import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/App.css";
import HomepageLogo from "./homepage_logo.svg";
import { Intro } from "./components/Intro/Intro.jsx";
// import { BreathworkIntro } from "./components/BreathworkIntro/BreathworkIntro.jsx";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Subscribe } from "../Subscribe/Subscribe";
import { CustomOfferings } from "./components/Offerings/Offerings";
import { SliderComponent as Testimonials } from "../Slider/SliderComponent.jsx";

export const HomePage = (props) => {
  const [styles, setStyles] = useState({});
  const ref = useRef(null);
  useEffect(() => {
    loadImage().then((val) => {
      setStyles({ backgroundImage: `url(${val})` });
    });
  }, []);

  const loadImage = () => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () =>
        resolve(
          "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/yosemite.jpg"
        );
      image.onerror = (err) => reject(err);
      image.src =
        "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/yosemite.jpg";
    });
  };

  //If you want to fly you need to let go of the shit that's holding you back.
  //Breathwork helps us to release limiting beliefs and negative energy that is holding us back from
  // being the person that we've always known we can be.

  // Interested in feeling more love, happiness and confidence through connecting with your heart, listening
  // to your intuition, and healing any trauma that is stopping you from living your truest potential.
  return (
    <div className="home-page">
      <div ref={ref} style={styles} className="background-image">
        <Header homepage={true} />
        <div className="logo-container">
          <img
            src={HomepageLogo}
            className="App-logo"
            alt="Lauren Breathwork"
          />
          <img
            alt="Breathwork San Francisco"
            className="hidden-image"
            style={{ visibility: "hidden", display: "none" }}
            src="https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/yosemite.jpg"
          />
        </div>
      </div>
      <div className="body">
        {/* <BreathworkIntro /> */}
        <Intro />
        <CustomOfferings />
        <Testimonials />
        <Subscribe />
      </div>
      <Footer />
    </div>
  );
};
