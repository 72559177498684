import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/css/App.css";
import { ProgressivePhoto } from "../../../ProgressivePhoto/ProgressivePhoto";

export const Intro = () => {
  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-text-container">
          <div className="intro-text-emphasized">
            <div className="from-lo-with-love">Breathwork Facilitator</div>
          </div>
          <div className="intro-text-paragraph">
          On this spiritual journey of life, I have encountered new ideas and practices that have completely rocked my world. I have come to realize that we are all innately pure - full of joy and love - but as life progresses, we are conditioned to seek happiness through external factors. We build up our egos, put walls up that block our light, and lose sight of the love within us.
          </div>
          <div className="intro-text-paragraph">
          Through my own personal practice, I’ve come to understand that we have the ability to decide again and again who we want to be and how we want to show up to this world. I’ve learned that there are tools available to us that can help us to release the negative energy that is bound to show up, so that we can return to the place of love and kindness in our hearts. By “remembering” who we are deep down, we are able to navigate this uncertain world with more grace and peace so that we can begin to cultivate more lightness and vibrance in our lives. 
          </div>
          <div>
            <Link className="link" to="/about">
              <div className="more-link">
                <span className="hover-underline">Read more </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="intro-image-container">
          <div>
            <h2 className="intro-image-name">Lo Madigan</h2>
          </div>
          {/* <img
            alt="Lauren Madigan"
            className="intro-image"
            src="https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/headshot.JPG"
          /> */}
          <ProgressivePhoto
            alt={"Lauren Madigan"}
            className={"intro-image"}
            overlaySrc={
              "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/headshot_tiny.JPG"
            }
            src={
              "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/headshot.JPG"
            }
          />
        </div>
      </div>
    </div>
  );
};
