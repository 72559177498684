import React from "react";
import CursiveLogo from "./cursive_logo_footer.svg";
import "../../assets/css/App.css";

export const Footer = () => {
  return (
    <div className="footer">
      <img src={CursiveLogo} alt="logo" className="cursive-logo" />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/fromlowithlove_/"
      >
        <i class="fa fa-instagram fa-2x" aria-hidden="true">
          {" "}
        </i>
      </a>
    </div>
  );
};
