import React from "react";
import "../../assets/css/Connect.css";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export const ConnectPage = () => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  // const [showErrorMessage, setShowErrorMessage] = useState(false);

  // const handleChangeFirstName = (event) => {
  //   setFirstName(event.target.value);
  // };

  // const handleChangeLastName = (event) => {
  //   setLastName(event.target.value);
  // };

  // const handleChangeEmail = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleChangeMessage = (event) => {
  //   setMessage(event.target.value);
  // };

  // const clearAllFiels = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setEmail("");
  //   setMessage("");
  // };

  // const displayErrorMessage = () => {
  //   setShowErrorMessage(true);
  // };

  // const removeErrorMessage = () => {
  //   setShowErrorMessage(false);
  // };

  // const sendData = () => {
  //   let form = {};
  //   form["firstName"] = firstName;
  //   form["lastName"] = lastName;
  //   form["email"] = email;
  //   form["message"] = message;
  //   const string = JSON.stringify(form);
  //   sendZap &&
  //     fetch("https://hooks.zapier.com/hooks/catch/8178253/ba4kauf/", {
  //       method: "POST",
  //       body: string,
  //     })
  //       .then(() => clearAllFiels())
  //       .catch((err) => displayErrorMessage());
  // };

  // const body = () => `${message} email=${email} name=${firstName} ${lastName}`

  // const buildHref = () => {
  //   ``
  // }

  // const sendZap = () => {
  //   return (
  //     firstName &&
  //     firstName.length > 0 &&
  //     lastName &&
  //     lastName.length > 0 &&
  //     email &&
  //     email.length > 0 &&
  //     message &&
  //     message.length > 0
  //   );
  // };
  return (
    <div className="connect-page-wrapper">
      <Header />
      <div className="connect-form-page">
        <div className="connect-form-container">
          <div className="contact-me-title">Let's chat!</div>
          <div style={{ paddingBottom: '16px', textAlign: 'left'}}>
            Interested in working or connecting with me? Reach out!
          </div>
          <div style={{ paddingBottom: '16px', textAlign: 'left'}}>
            I offer 1:1 & group breathwork sessions, both in-person and virtual, as well as mindfulness coaching.
          </div>
          <div style={{ paddingBottom: '16px', textAlign: 'left'}}>
            I am excited to work with you to customize our experience together.
          </div>
          <div style={{ paddingBottom: '16px', textAlign: 'left'}}>
            Send your inquiry to <a className="email" href='mailto:lo@fromlowithlove.com?subject=FromLoWithLove Contact Form'>lo@fromlowithlove.com</a>, including your name and message. I cannot wait to connect :)
          </div>
        </div>
        </div>
      <Footer />
    </div>
  );
};


 /* <div className="connect-form-container">
          <div className="contact-me-title">Let's chat!</div>
          <div className="connect-form">
            {showErrorMessage && (
              <div className="connect-error-message">
                Oops! An error has occurred. Please reload and try again.{" "}
                <span className="error-x" onClick={removeErrorMessage}>
                  X
                </span>
              </div>
            )}
            <input
              className="connect-input"
              type="text"
              name="first name"
              placeholder="First Name"
              value={firstName}
              autoComplete="off"
              required={true}
              onChange={handleChangeFirstName}
            />
            <input
              className="connect-input"
              type="text"
              name="last name"
              placeholder="Last Name"
              autoComplete="off"
              required={true}
              value={lastName}
              onChange={handleChangeLastName}
            />
            <input
              className="connect-input"
              type="text"
              name="name"
              placeholder="Email"
              autoComplete="off"
              required={true}
              value={email}
              onChange={handleChangeEmail}
            />
            <textarea
              className="connect-input connect-comment"
              placeholder="What's on your mind beautiful?"
              value={message}
              required={true}
              onChange={handleChangeMessage}
            />
            <button
              className="submit-button"
              value="Submit"
              onClick={() => sendData()}
            >
              Submit
            </button>
          </div>
        </div> */