import React from "react";
import "../../assets/css/App.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Subscribe } from "../Subscribe/Subscribe";

export const BreathworkOverview = () => {
  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="about-me-container">
        <div className="breathwork-overview-title">
          "Your mind controls your reality, but your breath controls your mind."
        </div>
        <h4 
          className="breathwork-subtext">
          Learn to master your breath, and you can learn to master your life.
        </h4>
        <div className="about-text-container">
          <div className="about-text-contents">
            <div className="about-text-paragraph">
              <div className="private-description">
                Breathwork is an active meditation that uses the breath to release
                energetic blocks and stuck emotions that we hold onto in our
                bodies. It allows us to get out of our thinking minds and be
                present in our feeling bodies. In this place, we are able to tap
                into the love and joy in our hearts, release doubt, and connect
                with our intuition. Breathwork allows us to “come home” to a calm 
                place of love within ourselves.
              </div>
            </div>
            <div className="about-text-paragraph">
              <div className="private-description-wrapper">
                <div className="breathwork-is-for-you-container">
                  <h4>Breathwork is for you if you want to:</h4>
                  <div>
                    <div>· release self-doubt or limiting beliefs</div>
                    <div>
                      · let go of negative emotions that are holding you back
                    </div>
                    <div>· experience more love, joy, and compassion</div>
                    <div>· let go of tension, stress and anxiety</div>
                    <div>· feel more in tune with yourself</div>
                    <div>· deepen your spiritual practice</div>
                    <div>· relax and feel at peace</div>
                    <div>· sleep better</div>
                  </div>
                  <h4>If you are breathing, breathwork is for you :) </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};
