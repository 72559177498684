import React, { useContext } from "react";
import "../../assets/css/App.css";
import Icon from "./more_icon.svg";
import { SidebarContext } from "../../App.jsx";

export const MoreIcon = (props) => {
  const ctx = useContext(SidebarContext);

  return (
    <div className="more-icon" onClick={ctx.toggleMenu}>
      <img alt="From Lo with Love" src={Icon} />
    </div>
  );
};
