import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/css/BreathworkIntro.css";

export const CustomOfferings = () => {
  return (
    <div className="home-offerings-container">
      <h1 className="intro-image-name">Custom Offerings</h1>
      <div className="offerings-list-container">
        <Link
          to="/private-healing"
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="custom-offering-item-container">
            <h3>1:1 Inner Healing Journey</h3>
            <div className="line"></div>
            <div className="offering-info-container">
              <div className="offering-info-line">$90</div>
              <div className="offering-info-line">
                75 minute breathwork session
              </div>
              <div className="offering-info-paragraph">
                During this session, we will go deeper into your healing. This
                will start with an email constultation to understand your
                intention and what you're hoping to get out of this session.
                Together, we will currate a playlist for your private healing
                breathwork session. The breathwork session will be 75 minutes
                over Zoom and will include 24 hours of follow up email for
                support and integration.
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
