import React from "react";
import "../../assets/css/Meditate.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
// import { CalendlyCalendar } from "../Meditate/components/CalendlyCalendar";
import { Subscribe } from "../Subscribe/Subscribe";

export const PrivateBreathwork = () => {
  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="meditate-body">
        <div className="breathwork-with-lo">Private Healing Breathwork</div>
        <div className="private-description-wrapper">
          <div className="private-description">
            A private, healing breathwork session is perfect for you if you want
            to dive deeper into your spiritual practice, get a more customized
            healing experience, or move past stuck emotions and limiting
            beliefs.
          </div>
        </div>
        <div className="private-description-wrapper">
          <div className="private-description">
            The session will start with you setting an intention. Around this
            intention, we will build a custom playlist and I will be able to
            better support you on your journey. During a private session, you
            are able to get out of your head, connect with your intuition and
            tap into your own infinite wisdom. This is where the healing takes
            place. You are your own healer. I will lovingly guide you to a place
            where you are able to feel this.
          </div>
        </div>
        {/* <CalendlyCalendar calendarId="1-1-healing-breathwork-session" /> */}
        <div className="private-description-wrapper">
          <div className="private-description">I take great joy and pride in delivering 
          breathwork experiences that are aligned with your exact needs. This begins with 
          an intro questionnaire to understand what you hope to get out of the session. I will also 
          work with you to create a customized playlist and breathwork meditation track. {" "}
        <a className="email" href="/connect">Contact me</a> to set up your session.
        </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};
