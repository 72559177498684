import React, { useState, useRef } from "react";
import "../../assets/css/App.css";

const omit = (obj, omitKey) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key];
    }
    return result;
  }, {});

const overlayStyles = {
  position: "absolute",
  filter: "blur(1px)",
  transition: "opacity ease-in 1000ms",
  clipPath: "inset(0)",
};

const visible = {
  visibility: "visible",
  display: "block",
};

const hidden = {
  visibility: "hidden",
  display: "none",
};

export const ProgressivePhoto = (props) => {
  const [highResImageLoaded, setHighResImageLoaded] = useState(false);
  const filteredProps = omit(props, "overlaySrc");
  const ref = useRef(null);
  const styles = highResImageLoaded ? visible : hidden;
  return (
    <span>
      <img
        style={styles}
        {...filteredProps}
        alt={props.alt}
        onLoad={() => {
          setHighResImageLoaded(true);
        }}
        ref={ref}
        src={props.src}
      />
      <img
        {...filteredProps}
        alt={props.alt}
        className={`${props.className} ${overlayStyles}`}
        {...(highResImageLoaded && {
          style: { opacity: "0", visibility: "hidden", display: "none" },
        })}
        src={props.overlaySrc}
      />
    </span>
  );
};
