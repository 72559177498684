import React from "react";
import "../../assets/css/FiveDays.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import Countdown from "react-countdown";

export const FiveDaysOfBreathwork = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <span className="count-down">
        {days} Days : {hours} Hours : {minutes} Minutes : {seconds} Seconds
      </span>
    );
  };
  const date = new Date(
    "Sun Jan 17 2021 12:00:00 GMT-0800 (Pacific Standard Time)"
  );
  return (
    <div className="five-days-page">
      <Header homepage={false} />
      <div className="image-clouds">
        <div className="cloud-text-container">
          <div className="header-text">5 Days of Breathwork</div>
          <div className="mini-header-text">
            A powerful breathing meditation to calm the mind, let go of negative
            emotions, and feel more love and joy.
          </div>
          <div className="breathe-now-button">Breathe Now!</div>
          <Countdown date={date} renderer={renderer} />
        </div>
      </div>
      <div className="five-days-section">
        <div className="five-days-section-side">
          <div className="five-days-section-side-photo lo-image"></div>
        </div>
        <div className="five-days-section-side beige-background-color">
          <div className="five-days-text-container">
            <div className="five-days-section-title">5 Days of Breathwork</div>
            <div className="line-separator"></div>
            <div className="five-days-text-date"> January 15th.</div>
            <div className="five-days-paragraph">
              <b>5 days. 1 Breathwork recording to your inbox each day.</b>{" "}
              Science backed research on how these breath techniques affectively
              reduces stress, increases wellness, improves lung strength, & lead
              to a healthier, happier life.
            </div>
            <div className="breathe-now-button blue">Sign Me Up!</div>
          </div>
        </div>
      </div>
      <div className="quote-section">
        <div className="five-days-quote">
          "Improper breathing is a common cause of ill health. If I had to limit
          my advice on healthier living to just one tip, it would be simply to
          learn how to breathe correctly. There is no single more powerful - or
          more simple - daily practice to further your health and well being
          than Breathwork."
        </div>
        <div className="five-days-quote-by">- Andrew Weil, MD</div>
      </div>
      <div className="five-days-section">
        <div className="five-days-section-side beige-background-color">
          <div className="five-days-text-container">
            <div className="five-days-section-title">Do you want to:</div>
            <div className="line-separator"></div>
            <div className="five-days-paragraph" style={{ paddingTop: "20px" }}>
              <div>- release self-doubt or limiting beliefs</div>
              <div>- let go of negative emotions that are holding you back</div>
              <div>- experience more love, joy, and compassion</div>
              <div>- let go of tension, stress and anxiety</div>
              <div>- feel more aligned with your purpose</div>
              <div>- heal past emotional trauma</div>
              <div>- deepen your spiritual practice</div>
              <div>- reduce stress</div>
              <div>- relax and feel at peace</div>
              <div>- sleep better</div>
            </div>
            <div className="breathe-now-button blue">Yes please!</div>
          </div>
        </div>
        <div className="five-days-section-side">
          <div className="five-days-section-side-photo"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
