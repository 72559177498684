import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { EventDetail } from "../Homepage/components/BreathworkIntro/EventDetail";

export const Schedule = () => {
  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="about-me-container">
        <div className="schedule-container">
          <div className="breathwork-overview-title">Upcoming Breathwork Sessions</div>
          <div className="">
            {/* <EventDetail
              title="Tuesday Sept. 20th @ 6:30pm pst"
              subtitle="Breathwork for Self-Love"
              info="Join via Zoom for a live Breathwork experience. 
              This 45 minute breathwork session will be the perfect energy reset. It will allow you to
               release stress & tension, while expanding into more love and joy."
              src={
                "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/trees-mediate.JPG"
              }
              overlaySrc={
                "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/trees-mediate-small.JPG"
              }
              href="https://www.eventbrite.com/e/breathwork-for-self-love-tickets-409583734977?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=escb"
              targetBlank={true}
              linkText="Sign Up"
            /> */}
            <EventDetail
              title="WITHIN Breathwork Meditation"
              subtitle="Every Saturday at 8:30am pst"
              info="Join via Zoom for a live breathwork meditation with WITHIN Meditation. 
          This 30-minute breathwork meditation will be the perfect start to the weekend. We'll be combining the breath, sound and meditation for a uniquely relaxing and healing experience."
          src={
                "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/trees-mediate.JPG"
              }
              overlaySrc={
                "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/trees-mediate-small.JPG"
              }
              href='https://clients.mindbodyonline.com/classic/mainclass?studioid=771079&tg=28&vt=20&lvl=&stype=-7&view=week&trn=0&page=&catid=&prodid=&date=9%2f8%2f2022&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1'
              targetBlank={true}
              linkText="Sign Up"
            />
      </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
