import React from "react";
import Slider from "./Slider";
import testimonials from "../Carousel/testimonials.js";
import "../../assets/css/Carousel.css";

export const SliderComponent = () => {
  return (
    <div className="slider-container">
      <h1>Testimonials</h1>
      <Slider title="Slider">
        {testimonials.map((t, index) => (
          <div key={index}>
            <div className="testimonial">{t.testimonial}</div>
            <p className="testimonee">{t.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};
