import styled from "styled-components";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${(props) => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${(props) => {
    if (!props.sliding) return "translateX(calc(-100% - 20px))";
    if (props.dir === PREV) return "translateX(calc(2 * (-100% - 20px)))";
    return "translateX(0%)";
  }};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 auto;
  flex-basis: 100%;
  margin-right: 20px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  order: ${(props) => props.order};
`;
