export default [
  {
    testimonial:
      "I have tried and failed at making meditation a part of my regular routine for the last four or five years, so I was initially skeptical when I first heard of breathwork. All I knew before my first session with Lauren was that breathwork was a type of 'active mediation' and at this point in my life, I was down to try anything to make me feel more grounded, centered and in touch with my ever so turbulent emotions. One phrase to sum up my experience? BEAUTIFULLY LIFE-CHANGING. I laughed, I cried and breathed to the beat of the music; I found a place within myself that had been hidden for so long and was ready to be healed. Thank you Lauren for bringing me back to my body and cannot wait for all of the sessions to come!",
    name: "~Brenna D.",
  },
  {
    testimonial:
      "Breathwork has been an easy way for me to become more in tune with myself. Throughout the day, there are emotions and tensions that arise that I often have a hard time identifying and resolving. The guided and structured aspects of Breathwork allow me to surrender fully and give in to the experience. I am able to focus and quiet my mind  because I am focused solely on my breathing. Breathwork is an experience that allows for both a physical and emotional release; a time that is just mine and that I truly look forward to. I am just a beginner but I am hooked! Lauren makes you feel so comfortable and at ease with the experience. Thanks Lauren!!",
    name: "~Molly D.",
  },
  {
    testimonial:
      "Breathwork with Lauren was one of the most incredible experiences. She made it very personal by asking what my intention for the session was in advance and also added a couple songs to the playlist that I wanted to include. My intention was vulnerability, so she really made me feel like I had a breakthrough as I went through the breathing technique and felt a wave of intense emotion that actually made me cry! I came out of the session feeling so much self love and happiness. Lauren is an incredible breathwork instructor and everyone should take one of her classes :)",
    name: "~Sheenae B.",
  },
  {
    testimonial:
      "Unbelievably satisfied with my experience doing breathwork with Lo. She does a great job of making you feel comfortable and informed going into the session. You can feel Lo’s genuine sense of compassion throughout the session that encourages you to really let go and experience all of the thoughts and sensations. Breathwork has become an important part of my weekly routine, and I am so grateful to have such a caring facilitator in Lo!",
    name: "~Annie W.",
  },
];
