import React from "react";
import "../../assets/css/App.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Subscribe } from "../Subscribe/Subscribe";

export const Blog = () => {
  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="about-me-container">
        <div className="about">Blog coming soon!</div>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};
