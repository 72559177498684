import React from "react";
import "../../assets/css/App.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Subscribe } from "../Subscribe/Subscribe";
import { ProgressivePhoto } from "../ProgressivePhoto/ProgressivePhoto";

export const About = () => {
  return (
    <div className="about-page">
      <Header homepage={false} />
      <div className="about-me-container">
        <div className="about">Lauren Madigan</div>
        <div className="about-mini">
          breathwork facilitator, self-love advocate, mindfulness enthusiast
        </div>
        <div className="about-me-photo-container">
          <ProgressivePhoto
            alt={"Lauren Madigan"}
            className={"about-me-photo"}
            src={
              "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/favorite.JPG"
            }
            overlaySrc={
              "https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/favorite_tiny.JPG"
            }
          />
        </div>
        <div className="about-text-container">
          <div className="about-text-contents">
            <div className="about-text-title">
              Welcome in! I'm Lo
              <span role="img" aria-label="wave-emoji">
                👋
              </span>
            </div>
            <div className="im-lo">
              Certified breathwork facilitator & mindset enthusiast. I'm so happy that our worlds collided.
            </div>
            <div className="about-text-paragraph">Hi! I’m Lauren ~ Lo ~ Madigan, a California native with a passion for spirituality, wellness, and mental health. I’m here with the strong belief that we all have the ability to live a vibrant life full of love and joy... But instead of telling you about my outlook on life, I wanted to share with you a little something that I wrote about breathwork.</div>
            <div className="quotes-paragraph">
              <div className="quotes">The first time I did breathwork, I walked home in silence. I say “walked” but really it felt like I was floating.</div>
              <div className="quotes">I took the quiet side streets home to protect this beautiful energy that I had created around myself.</div>
              <div className="quotes">For the first time in my life, my mind was quiet.</div>
              <div className="quotes">For the first time in my life that I could remember, I wasn’t doubting myself.</div>
              <div className="quotes">For what felt like the first time in a long time, I didn’t have a continuous stream of negative self talk.</div>
              <div className="quotes">It was so freeing and so liberating.</div>
              <div className="quotes">Before that first class, I didn’t realize an inner peace that felt this good was attainable.</div>
              <div className="quotes">Before that first class, I didn’t realize that I could love myself so completely.</div>
              <div className="quotes">Before that first class, a part of me felt a bit lost, thinking that deep down maybe something was wrong with me.</div>
              <div className="quotes">Breathwork unlocked layers of love in me that I didn’t know existed.</div>
              <div className="quotes">It didn’t change anything about me. Instead it peeled back the negative stories that I had built up in my mind, and it showed me my true Self, which was joyful, light and full of love.</div>
              <div className="quotes">That first breathwork class changed my life.</div>
              <div className="quotes">And for that, I am forever grateful.</div>
 
            </div>
            <div className="about-text-paragraph">After taking my first class, I knew that my life was forever changed. I teach breathwork because I believe that everyone deserves to feel the way that I felt after that first class. I hope that we have the opportunity to connect and share our love with each other <span role="img" aria-label="heart">❤️</span></div>
          </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};
