import React from "react";
import "../../../../assets/css/BreathworkIntro.css";
import { ProgressivePhoto } from "../../../ProgressivePhoto/ProgressivePhoto";

export const EventDetail = ({
  title,
  subtitle,
  info,
  src,
  overlaySrc,
  href,
  targetBlank,
  linkText,
}) => {
  return (
    <a
      className="event-details-link"
      target={targetBlank ? "_blank" : ""}
      href={href}
    >
      <div className="event-detail-container">
      {
        (src && overlaySrc) && 
        <ProgressivePhoto
          alt={"Lauren Madigan"}
          className={"about-me-photo"}
          src={src}
          overlaySrc={overlaySrc}
        />
      }
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
        <div className="event-detail-info-container">{info}</div>
        <div className="link-text-button">{linkText}</div>
      </div>
    </a>
  );
};
