import React, { useState } from "react";

export const RightArrow = (props) => {
  const [hovered, setHovered] = useState(false);
  const faSize = hovered ? "fa-4x" : "fa-3x";
  const mouseEnter = () => setHovered(true);
  const mouseLeace = () => setHovered(false);

  return (
    <div
      className="arrow"
      onClick={props.goToNextSlide}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeace}
    >
      <i className={`fa fa-angle-right ${faSize}`} aria-hidden="true"></i>
    </div>
  );
};
