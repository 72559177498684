import React, { createRef } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/App.css";
import GreyLogo from "./header_light_grey_logo.svg";
import { MoreIcon } from "./MoreIcon.jsx";

export const Header = (props) => {
  const ref = createRef(null);
  const linkClassName = props.homepage ? "white" : "blue-text";

  return (
    <div
      ref={ref}
      className={`top-nav ${props.homepage ? "" : "top-nav-box-shadow"}`}
    >
      <div className="header-link-container">
        {!props.homepage && (
          <Link className="header-logo link" to="/">
            <img alt="From Lo with Love" src={GreyLogo} />
          </Link>
        )}
        <Link className="link" to="/about">
          <h2 className={`heading-text ${linkClassName} light-green-hover`}>
            About
          </h2>
        </Link>
        <Link className="link" to="/breathwork">
          <h2 className={`heading-text ${linkClassName} light-green-hover`}>
            Breathwork
          </h2>
        </Link>
        <Link className="link" to="/schedule">
          <h2 className={`heading-text ${linkClassName} light-green-hover`}>
            Schedule
          </h2>
        </Link>
        <Link className="link" to="/private-healing">
          <h2 className={`heading-text ${linkClassName} light-green-hover`}>
            Private Sessions
          </h2>
        </Link>
        <Link className="link" to="/connect">
          <h2 className={`heading-text ${linkClassName} light-green-hover`}>
            Contact
          </h2>
        </Link>
        <Link
          to="/schedule"
          style={{ textDecoration: "none", width: "100px" }}
          className="sign-up-button"
        >
          Book Now
        </Link>
      </div>
      <MoreIcon />
    </div>
  );
};
